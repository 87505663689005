<template>
  <f7-page class="">
    <template #fixed>
      <CommonNavigationComponent :title="$t.getTranslation('LBL_SURVEY')" type="back" :search="false" />
    </template>
    <div class="survey">
      <SurveyViewComponent v-if="survey" :data="survey" :f7route="f7route" :f7router="f7router" />
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent } from 'vue'
import { get } from '@/utils/axios'
import { useStore } from '@/store'
// import CommonNavigationComponent from '@/components/navigations/CommonNavigationComponent.vue'
// import SurveyViewComponent from '@/components/SurveyViewComponent.vue'

const CommonNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "common-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/CommonNavigationComponent.vue"));
const SurveyViewComponent = defineAsyncComponent(() => import(/* webpackChunkName: "survey-view" */ /* webpackMode: "lazy" */ "@//components/SurveyViewComponent.vue"));

export default defineComponent({
  name: 'SurveyListPage',
  components: {
    CommonNavigationComponent,
    SurveyViewComponent
  },
  props: {
    SurveyKey: String,
    f7router: Object,
    f7route: Object
  },
  setup(props) {
    let survey = ref(null)
    const $t = inject('$translation')

    const store = useStore();
    
    const getData = async () => {
      let ret = await get(`/survey/view`, {
        SurveyKey: props?.f7route?.query?.key,
        LanguageCode: $t.getLanguage()
      })
      survey.value = ret
    }

    onMounted(async () => {
      await getData()
    })

    return {
      survey
    }
  }
})
</script>

<style></style>
