<template>
  <div class="full-survey">
    <img class="survey-top" loading="lazy" :src="require('@/assets/images/login-bg-top.png')" />
    <div class="survey-bg">
      <div class="survey-title">
        <h3>{{ data.SurveyName || '---' }}</h3>
        <p>{{ data.SurveyDescription || '---' }}</p>
      </div>

      <div class="survey-question" v-for="(item, index) in data.Items" :key="'survey_items_' + item.SurveyItemId">
        <span>Q{{ index + 1 }})</span> {{ item.SurveyQuestion }}
        <div v-if="item.SurveyItemAnswerType == 'RATINGS'" style="padding-top: 10px;">
          <div class="list list-two">
            <div class="list-rate">
              <star-rating :isShowNumber="true" :star-size="22" :show-rating="false" style="padding: 0 2px;" @update:rating="setRating" :id="item.SurveyItemId"></star-rating>
            </div>
          </div>
        </div>

        <div v-if="item.SurveyItemAnswerType == 'FREETEXT'" style="padding-top: 10px;">
          <div class="list">
            <li class="item-input">
              <div class="item-inner">
                <div class="item-input-wrap">
                  <input type="text" placeholder="Type Answer" v-model="formData[item.SurveyItemId]" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
          </div>
        </div>

        <div v-if="item.SurveyItemAnswerType == 'YESNO'" style="padding-top: 10px;">
          <div class="list">
            <!-- <label class="item-radio item-radio-icon-start item-content">
              <input type="radio" name="demo-radio-start1" value="Option1" /><i class="icon icon-radio"></i>
              <div class="item-title">Option1</div>
            </label>
            <label class="item-radio item-radio-icon-start item-content">
              <input type="radio" name="demo-radio-start1" value="Option2" /><i class="icon icon-radio"></i>
              <div class="item-title">Option2</div>
            </label>
            <label class="item-radio item-radio-icon-start item-content">
              <input type="radio" name="demo-radio-start1" value="Option3" /><i class="icon icon-radio"></i>
              <div class="item-title">Option3</div>
            </label>
            <label class="item-radio item-radio-icon-start item-content">
              <input type="radio" name="demo-radio-start1" value="Option4" /><i class="icon icon-radio"></i>
              <div class="item-title">Option4</div>
            </label> -->
            <f7-list no-hairlines>
              <f7-list-input
                v-model:value="formData[item.SurveyItemId]"
                :name="item.SurveyQuestion"
                :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
                :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                floating-label
                outline
                type="select"
                validate
                style="background-color: #f6f6f6;"
              >
                <option value="YES">{{ $t.getTranslation('LBL_YES') }}</option>
                <option value="NO">{{ $t.getTranslation('LBL_NO') }}</option>
                <option value="MAYBE">{{ $t.getTranslation('LBL_MAYBE') }}</option>
              </f7-list-input>
            </f7-list>
          </div>
        </div>

        <div v-if="item.SurveyItemAnswerType == 'PRODUCTS'">
          <!-- <div class="list list-two">
            <label class="item-radio item-radio-icon-start item-content">
              <img class="list-img" :src="require('@/assets/images/demo/wines/2.png')" />
              <div class="list-input">
                <input type="radio" name="demo-radio-start2" value="Option1" /><i class="icon icon-radio"></i>
                <div class="item-title">Option1</div>
              </div>
            </label>
            <label class="item-radio item-radio-icon-start item-content">
              <img class="list-img" :src="require('@/assets/images/demo/wines/3.png')" />
              <div class="list-input">
                <input type="radio" name="demo-radio-start2" value="Option2" /><i class="icon icon-radio"></i>
                <div class="item-title">Option2</div>
              </div>
            </label>
          </div> -->
        </div>
      </div>

      <div class="survey-btn">
        <f7-button fill @click="submit">
          {{ $t.getTranslation('LBL_SUBMIT') }}
        </f7-button>
      </div>
    </div>

    <img class="survey-bot" loading="lazy" :src="require('@/assets/images/login-bg-bot.png')" />
  </div>
</template>

<script>
import { defineComponent, ref, inject, onMounted, reactive, computed, defineAsyncComponent } from 'vue'
import { f7 } from 'framework7-vue'
import { post } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'
import { useStore } from '@/store'

// import StarRating from '@/components/rating/star-rating.vue'

const StarRating = defineAsyncComponent(() => import(/* webpackChunkName: "star-rating" */ /* webpackMode: "lazy" */ "@/components/rating/star-rating.vue"));

export default defineComponent({
  name: 'SurveyViewComponent',
  components: { StarRating },
  props: {
    data: Object,
    f7router: Object,
    f7route: Object
  },
  setup(props) {
    let formData = reactive({})
    const store = useStore()
    const userToken = computed(() => store.getters['user/getData']?.Token)
    const $t = inject('$translation')

    const setRating = (rating) => {
      if (rating?.id) {
        formData[rating.id] = rating.rating
      }
    }

    onMounted(() => {
      if (!helpers.isBlank(userToken.value)) {
        store.dispatch('user/setData', { SurveyKey: null })
        return
      } else {
        store.dispatch('user/setData', { SurveyKey: props.data.SurveyKey })
        props.f7router.navigate({ name: 'loginPage' })
        return
      }
    })

    const submit = async () => {
      let keys = Object.keys(formData)
      if (keys.length <= 0) {
        throw new Error($t.getTranslation('ERR_REQUIRED_MISSING_FIELDS'))
      }

      formData.SurveyId = props.data.SurveyId
      let ret = await post('/survey/user', formData)
      if (ret) {
        helpers.createNotification({
          type: 'info',
          title: $t.getTranslation('LBL_INFO'),
          message: $t.getTranslation('LBL_INFO_SUCCESS_SUBMIT_SURVEY')
        })
        props.f7router.navigate({ name: 'shopPage' })
        return
      }

      throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
    }

    return {
      formData,
      submit,
      setRating
    }
  }
})
</script>

<style scoped>
.survey-bg {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 10px;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
}
.list input[type='text'] {
  font-size: 16px;
  color: #29527d;
}
.survey-question span {
  font-weight: 600;
}
.survey-question {
  padding: 15px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  min-width: 290px;
}

li {
  list-style: none;
}
.list {
  width: 100%;
}
.list-two {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.list .item-title {
  font-weight: 600;
  color: #29527d;
}
/* .list .item-content {
  justify-content: flex-start;
  background-color: #dddcdc !important;
  margin: 10px 0px;
  border-radius: 10px;
  font-weight: 600;
  color: #29527d;
} */
.list-two .item-content {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}
.survey-radio {
  background-color: #dee5ed;
  border-radius: 10px;
  color: #29527d;
  margin-bottom: 10px;
}
.survey-btn {
  width: 100%;
}
i.icon.icon-radio {
  background-color: #fff;
  border: 2px solid #29527d;
}
.list-img {
  width: 100%;
  height: 200px;
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
}
.list-img img {
  width: 100%;
}
.list-input {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 10px;
  margin-left: 20px;
  width: 100%;
}
.list-input i.icon.icon-radio {
  margin-right: 15px;
}
.list-rate .vue-star-rating-star {
  padding: 0 5px;
}
.survey-top {
  position: absolute;
  z-index: -1;
  top: -10px;
  right: -30px;
  width: 100%;
}
.survey-bot {
  position: absolute;
  z-index: -1;
  bottom: -30px;
  left: -30px;
  width: 100%;
}
.full-survey {
  position: relative;
}
</style>
